// src/App.js
import React from 'react';
import VoiceTextForm from './VoiceTextForm';

function App() {
  return (
    <div className="App">
      <VoiceTextForm />
    </div>
  );
}

export default App;
