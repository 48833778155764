// src/VoiceTextForm.js
import React, { useState, useEffect } from "react";
import { ReactMic } from "react-mic";
import axios from "axios";
const key_ = "sk_de491e7598570b82fce905eeac2f4a84d7077ddedd6c4ce2";
const VoiceTextForm = () => {
  const [recording, setRecording] = useState(false);
  const [blobObject, setBlobObject] = useState(null);
  const [voiceList, setVoiceList] = useState([]);
  const [text, setText] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [audioURL, setAudioURL] = useState(null);
  const [audioURLNew, setAudioURLNew] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);

  useEffect(() => {
    getVoiceList();
  }, []);

  const startRecording = () => {
    setRecording(true);
    setAudioURL(null);
  };

  const stopRecording = () => {
    setRecording(false);
  };

  const onData = (recordedBlob) => {
    //  console.log("real-time data", recordedBlob);
  };

  const onStop = (recordedBlob) => {
    setBlobObject(recordedBlob.blob);
    setAudioURL(URL.createObjectURL(recordedBlob.blob));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!blobObject) {
      alert("Please record your voice first!");
      return;
    }
    setAudioURL(null);

    const formData = new FormData();
    formData.append("files", blobObject, "audio.wav");
    formData.append("name", text);
    const obj = Object.fromEntries(formData);

    try {
      setLoadingNew(true);
      await axios.post("https://api.elevenlabs.io/v1/voices/add", obj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "xi-api-key": key_,
        },
      });
      setLoadingNew(false);
      alert("Saved");
      // setVoiceList(response.data);
    } catch (error) {
      setLoadingNew(false);
      console.error("Error submitting data:", error);
      alert("Failed to submit data.");
    }
  };

  async function getVoiceList(params) {
    try {
      const response = await axios.get("https://api.elevenlabs.io/v1/voices", {
        headers: {
          "Content-Type": "multipart/form-data",
          "xi-api-key": key_,
        },
      });

      setVoiceList(response.data.voices);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit data.");
    }
  }

  async function handleSubmitTextToVoice(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const formdata = new FormData(e.target);
      const obj = Object.fromEntries(formdata);
      const url = `https://api.elevenlabs.io/v1/text-to-speech/${obj.voice_id}`; // Replace with your API URL
      const headers = {
        "Content-Type": "application/json",
        "xi-api-key": key_,
      };

      const data = {
        text: obj.text,
        model_id: "eleven_turbo_v2_5",
        voice_settings: {
          stability: 0.5,
          similarity_boost: 0.5,
        },
      };

      const response = await axios.post(url, data, {
        headers,
        responseType: "blob", // Important to set response type as blob to handle binary data
      });

      const blob = new Blob([response.data], { type: "audio/mpeg" });
      const downloadUrl = URL.createObjectURL(blob);
      setAudioURLNew(downloadUrl);
      // setLoading(false);
      setLoading(false);
      //setAudioURL(response);
    } catch (error) {
      console.error("Error submitting data:", error.message);
      alert("Failed to submit data.");
      setLoading(false);
    }
  }

  async function getVoiceHistory(id) {}

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Record Your Voice</h2>
          <div className="text-center mb-3">
            <ReactMic
              record={recording}
              className="sound-wave"
              onStop={onStop}
              onData={onData}
              strokeColor="#FF4081"
              backgroundColor="#f8f9fa"
              height={100}
              width={300}
            />
          </div>
          <div className="text-center mb-3">
            <button
              onClick={startRecording}
              className="btn btn-primary mr-2"
              type="button"
              disabled={recording}
            >
              {recording ? "Recording..." : "Start Recording"}
            </button>
            <button
              onClick={stopRecording}
              className="btn btn-danger"
              type="button"
              disabled={recording ? false : true}
            >
              Stop Recording
            </button>
            {audioURL && (
              <div className="text-center mb-3">
                <audio controls src={audioURL} className="mb-3"></audio>
                <a
                  href={audioURL}
                  download="recorded-audio.wav"
                  className="btn btn-info"
                >
                  Download Audio
                </a>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="text">Enter Your Voice Name:</label>
              <input
                id="text"
                className="form-control"
                rows="3"
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="btn btn-success btn-block mt-4"
              disabled={loadingNew}
            >
              {loadingNew ? "Saving" : "Submit"}
            </button>
          </form>
        </div>
      </div>

      <div className="card shadow-lg mt-5 mb-5 pb-5">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Text To Voice</h2>
          {audioURLNew && (
            <div className="text-center mb-3">
              <audio controls src={audioURLNew} className="mb-3"></audio>
              <a
                href={audioURLNew}
                download="recorded-audio.wav"
                className="btn btn-info"
              >
                Download Audio
              </a>
            </div>
          )}
          <form onSubmit={handleSubmitTextToVoice}>
            <div className="form-group">
              <label htmlFor="text">Enter Text:</label>
              <textarea
                id="text"
                className="form-control"
                rows="3"
                //  value={text}
                defaultValue=""
                // onChange={(e) => setText(e.target.value)}
                name="text"
              />
            </div>

            <div className="form-group">
              <label htmlFor="dropdown">Choose Your Voice:</label>
              <select
                id="dropdown"
                className="form-control"
                value={dropdownValue}
                onChange={(e) => setDropdownValue(e.target.value)}
                name="voice_id"
              >
                <option value="">Select</option>
                {voiceList.map((v, i) => (
                  <option value={v.voice_id}>{v.name}</option>
                ))}
              </select>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-block mt-4"
              disabled={loading}
            >
              {loading ? "Loading..." : "Generate"}
            </button>
          </form>
        </div>
      </div>

      <div className="card shadow-lg mt-5 mb-5 pb-5">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Fetch By Voice</h2>
          <div>
            {voiceList.map((v, i) => (
              <p
                value={v.voice_id}
                className="btn btn-sm me-3 mb-2 btn-success"
                onClick={() => getVoiceHistory()}
              >
                {v.name}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceTextForm;
